@import "../../styles/variables";

.heading {
	display: flex;
	align-items: flex-end;
	margin-top: size(80);
	margin-bottom: size(44);

	font-size: size(20);

	h2 {
		margin-bottom: size(-4);

		font-size: size(32);
		font-weight: normal;

		&:not(:last-child) {
			margin-right: size(24);
		}
	}
}
