@import "../../styles/variables";

.pill {
	display: inline-block;
	padding: size(6) size(16);

	color: $colour-white;
	font-size: size(16);
	font-weight: 500;

	background-color: $colour-black;
	border-radius: size(6);

	a {
		color: currentColor;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&.white {
		color: $colour-black;
		background-color: $colour-white;
	}

	&.blue {
		background-color: $colour-blue;
	}

	&.grey {
		background-color: #858585;
	}
}
