@use "sass:math";
@import "../../styles/variables";

.banner {
	position: relative;
	min-height: 50vh;
	max-height: 80vh;
	margin-top: size(8);
	margin-bottom: size($spacer);

	background-color: $colour-grey-lightest;
	border-radius: size(20);
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: math.div(918, 1720) * 100%;

		@media #{$sm-viewport-down} {
			display: none;
		}
	}

	@media #{$sm-viewport-down} {
		max-height: unset;
		background-color: $colour-black;
	}
}

.img {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	@media #{$sm-viewport-down} {
		position: initial;
	}

	div,
	span,
	img {
		display: block !important;
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
		vertical-align: middle;
	}
}

.content {
	position: absolute;
	left: size(16);
	bottom: size(16);

	width: calc(100% - #{size(16 * 2)});
	max-width: size(440);
	padding: size($spacer) size(32) size(32);

	color: $colour-white;

	background-color: $colour-black;
	border-radius: size(12);
	transform: translateY(calc(100% + #{size(16)}));
	animation: bannerContentIn 1.5s ease forwards;
	animation-delay: 0.25s;

	@keyframes bannerContentIn {
		from { transform: translateY(calc(100% + #{size(16)})) }
		to   { transform: translateY(0%) }
	}

	@media #{$sm-viewport-down} {
		position: relative;
		left: 0;
		bottom: 0;
		width: 100%;

		border-radius: 0;

		h1 {
			font-size: size(32);
		}
	}
}

.white {
	color: $colour-black;
	background-color: $colour-white;
}

.blue {
	background-color: $colour-blue;
}

.grey {
	color: $colour-black;
	background-color: $colour-grey;
}
