@import "../../styles/variables";

.newsCard {
	grid-column: span 4;
	align-self: stretch;

	color: $colour-black;
	text-decoration: none;

	background-color: $colour-grey-lightest;
	border-radius: size(20);
	overflow: hidden;

	@media #{$md-viewport-down} {
		grid-column: span 6;
	}

	@media #{$sm-viewport-down} {
		grid-column: span 12;
	}

	div,
	img {
		width: 100%;
		vertical-align: middle;
	}

	strong {
		font-size: size(24);
		font-weight: 500;
	}

	&:hover {
		text-decoration: none;
	}
}

.copy {
	display: block;
	padding: size(27) size(40) size(36);
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: size(23);

	span {
		color: $colour-blue;
	}

	time {
		opacity: 0.5;
	}
}
