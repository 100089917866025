@import "../../styles/variables";

.productCard {
	display: inline-block;
	padding: size(24) 0;
	width: 100%;

	color: $colour-black;
	font-size: size(16);

	background-color: $colour-grey-lightest;
	border-radius: size(20);
	
	transition: ease-in-out background-color 0.4s, ease-in-out box-shadow 0.4s;
	cursor: pointer;

	&:hover {
		background-color: #ffffff;
		box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
		border-bottom: none;
		text-decoration: none;
	}
}

.imgs {
	position: relative;
	display: block;
	//aspect-ratio: 320 / 325;
	padding-top: 325 / 320 * 100%;
	overflow: hidden;
	pointer-events: none;
	mix-blend-mode: multiply;

	div,
	img {
		width: 100%;
		height: auto;
		vertical-align: middle;
		object-fit: contain;
	}

	> span {
		position: absolute;
		top: 0;
		display: block;
		font-size: 0;
		white-space: nowrap;
	}
}

.copy {
	display: block;
	padding: size(16) size(24) 0;
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: size(3);
}

.brand {
	opacity: 0.5;
}

.name {
	font-size: size(24);
	font-weight: 500;
}

.variants {
	display: flex;
	align-items: center;
	margin-top: size(8);

	> span {
		position: relative;

		display: block;
		width: size(16);
		height: size(16);
		margin-right: size(8);

		background-color: var(--fill);
		border-radius: 50%;

		&[style*="#fff"]::after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;

			display: block;
			width: calc(100% - 2px);
			height: calc(100% - 2px);

			border: 1px solid #DEDEDE;
			border-radius: 50%;
		}

		> span {
			position: absolute;
			z-index: 2;

			display: block;
			width: 100%;
			height: 100%;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;

				display: block;
				width: size(20);
				height: size(20);

				border: 2px solid $colour-black;
				border-radius: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}
	
	.price {
		margin-left: auto;
		width: auto;
		line-height: 1em;
	}
}
