@import "../../styles/variables";

.wrap {
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@media #{$md-viewport-down} {
		grid-template-columns: 1fr 1fr;
	}

	@media #{$sm-viewport-down} {
		display: block;

		.widget:not(:last-child) {
			margin-bottom: size($spacer * 0.5);
		}
	}
}

.widget {
	position: relative;
	display: flex;
	align-items: center;
	grid-column: span var(--widget-columns, 1);
	grid-row: span var(--widget-rows, 1);
	min-height: calc(#{size(400)} * var(--widget-rows, 1));

	border-radius: size(20);
	background-color: $colour-grey-lightest;
	overflow: hidden;

	@media #{$sm-viewport-down} {
		display: block;
	}

	&:hover {
		text-decoration: none;
	}
}

.img {
	height: 100%;

	span,
	img {
		display: block !important;
		width: 100% !important;
		height: 100% !important;
		vertical-align: middle;
		object-fit: cover;
	}
}

.text {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: size($spacer);
}

.copy {
	position: relative;
	z-index: 2;
	display: block;
	margin: size(-$spacer);
	padding: size($spacer);

	border-radius: size(12);

	h2 {
		margin-bottom: size(4);

		@media #{$sm-viewport-down} {
			font-size: size(32);
		}
	}

	p {
		&:not(:last-child) {
			margin-bottom: size(4);
		}

		.widget:not(.none) & {
			opacity: 0.7;
		}
	}
}

.white {
	color: $colour-black;
	background-color: $colour-white;

	.copy {
		background-color: $colour-white;
	}
}

.black {
	color: $colour-white;
	background-color: $colour-black;

	.copy {
		background-color: $colour-black;
	}
}

.blue {
	color: $colour-white;
	background-color: $colour-blue;

	.copy {
		background-color: $colour-blue;
	}
}

.none {
	.copy {
		color: $colour-black;
		background-color: transparent;
	}
}

.textCenter {
	text-align: center;
}

.imageFill {
	align-items: flex-start;

	&:not(.none) {
		.copy {
			margin: size(-$spacer + 16);
		}
	}

	.copy {
		color: $colour-white;
	}

	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.pill {
	position: absolute;
	top: size(8);
	right: size(8);

	.white & {
		color: $colour-white;
		background-color: $colour-black;
	}

	.blue & {
		color: $colour-blue;
		background-color: $colour-white;
	}
}

.cta {
	margin-top: size(100);
}
