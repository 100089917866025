@import "../../styles/variables";

.deck {
	position: relative;
	width: 100vw;
	max-width: unset;
}

.inline {
	display: grid;
	width: unset;
	margin: unset;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-column: span 9;
	align-self: start;

	@media #{$container-down} {
		grid-column: span 12;
	}
}

.pullLeft {
	grid-column: 1 / -1;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.scroll {
	font-size: 0;
	white-space: nowrap;

	overflow: auto;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	scroll-padding: calc((100vw - #{$container-width}) / 2);

	cursor: grab;

	&:active {
		cursor: grabbing;

	}

	> a {
		max-width: size(320);
		scroll-snap-align: start;

		&:not(:last-child) {
			margin-right: size($spacer);
		}
	}

	&::before,
	&::after {
		content: '';
		display: inline-block;
		width: calc((100vw - #{$container-width}) / 2);
		height: 100%;
	}

	@media #{$container-down} {
		scroll-padding: size($spacer);

		&::before,
		&::after {
			width: size($spacer);
		}
	}

	@media #{$md-viewport-down} {
		scroll-padding: size($spacer*0.5);

		&::before,
		&::after {
			width: size($spacer*0.5);
		}
	}
}

.noSnap {
	scroll-snap-type: unset;
}

.dragging * {
	pointer-events: none;
}

.edges {
	position: absolute;
	z-index: 3;
	top: 0;

	display: block;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: 2;

		display: block;
		width: calc((100vw - #{$container-width}) / 2);
		height: 100%;
		background-image: linear-gradient(
			to right,
			hsla(0, 0%, 100%, 0.8) 0%,
			hsla(0, 0%, 100%, 0.79) 16%,
			hsla(0, 0%, 100%, 0.761) 29.6%,
			hsla(0, 0%, 100%, 0.717) 41.1%,
			hsla(0, 0%, 100%, 0.66) 50.8%,
			hsla(0, 0%, 100%, 0.593) 58.8%,
			hsla(0, 0%, 100%, 0.518) 65.4%,
			hsla(0, 0%, 100%, 0.44) 70.9%,
			hsla(0, 0%, 100%, 0.36) 75.4%,
			hsla(0, 0%, 100%, 0.282) 79.2%,
			hsla(0, 0%, 100%, 0.207) 82.5%,
			hsla(0, 0%, 100%, 0.14) 85.6%,
			hsla(0, 0%, 100%, 0.083) 88.6%,
			hsla(0, 0%, 100%, 0.039) 91.9%,
			hsla(0, 0%, 100%, 0.01) 95.6%,
			hsla(0, 0%, 100%, 0) 100%
		);
	}

	&::after {
		right: 0;
		background-image: linear-gradient(
			to left,
			hsla(0, 0%, 100%, 0.8) 0%,
			hsla(0, 0%, 100%, 0.79) 16%,
			hsla(0, 0%, 100%, 0.761) 29.6%,
			hsla(0, 0%, 100%, 0.717) 41.1%,
			hsla(0, 0%, 100%, 0.66) 50.8%,
			hsla(0, 0%, 100%, 0.593) 58.8%,
			hsla(0, 0%, 100%, 0.518) 65.4%,
			hsla(0, 0%, 100%, 0.44) 70.9%,
			hsla(0, 0%, 100%, 0.36) 75.4%,
			hsla(0, 0%, 100%, 0.282) 79.2%,
			hsla(0, 0%, 100%, 0.207) 82.5%,
			hsla(0, 0%, 100%, 0.14) 85.6%,
			hsla(0, 0%, 100%, 0.083) 88.6%,
			hsla(0, 0%, 100%, 0.039) 91.9%,
			hsla(0, 0%, 100%, 0.01) 95.6%,
			hsla(0, 0%, 100%, 0) 100%
		);
	}
}
